import React, { useContext } from "react"
import { graphql } from "gatsby"
import styled, { ThemeContext } from "styled-components"
import Inner from "../inner"
import BreadCrumbs from "../breadcrumbs"
import BottomColorNav from "../bottom-color-nav"
import SEO from "../seo"
import SVG from "../svg"
import Layout from "../layout"

const ContentStyled = styled.div`
  margin: 130px auto 0px auto;

  @media (max-width: 840px) {
    padding: 0 10px;
  }

  h1 {
    color: ${(p) => p.theme.DarkBlue};
    font-size: 9.2em;
    text-transform: uppercase;
    margin: 20px 0;
    line-height: 0.9em;
    font-family: "interstate-compressed";
    @media (max-width: 768px) {
      font-size: 5em;
    }
  }

  .body {
    margin: 0 0 100px 0;
    color: ${(p) => p.theme.DarkBlue};
    a {
      color: ${(p) => p.theme.DarkBlue};
    }
  }
`

const SocialStyled = styled.div`
  width: 300px;
  padding: 20px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;

  span {
    font-weight: bold;
    color: ${(p) => p.theme.DarkBlue};
    font-size: 14px;
    display: inline-block;
    padding: 0 5px;
  }

  .print {
    padding-left: 100px;
  }

  img {
    width: 32px;
    margin-right: 20px;
  }
`

const Social = ({ sharing }) => {
  if (typeof window === "undefined") return ""
  const url = window.location.href
  const twCopy = sharing.twitter_copy
  const fbUrl = `https://www.facebook.com/sharer.php?u=${url}`
  const twUrl = `https://twitter.com/intent/tweet?text=${twCopy + " "}${url}`
  const mailUrl = `mailto:?subject=Position&body=${url}`
  const inUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}`
  return (
    <SocialStyled>
      <span>SHARE</span>
      <a href={fbUrl} target="_blank" rel="noopener noreferrer">
        <img src="/icons/fb.png" />
      </a>
      <a href={inUrl} target="_blank" rel="noopener noreferrer">
        <img src="/icons/in.png" />
      </a>
      <a href={twUrl} target="_blank" rel="noopener noreferrer">
        <img src="/icons/tw.png" />
      </a>
      <a href={mailUrl} target="_blank" rel="noopener noreferrer">
        <img src="/icons/email.png" />
      </a>
    </SocialStyled>
  )
}

const Content = ({ data }) => {
  const theme = useContext(ThemeContext)
  const post = data.allWordpressWpNews.edges[0].node
  const {
    search_title,
    search_description,
    share_headline,
    share_comment,
  } = post.acf

  const trail = [
    {
      label: "NEWS",
      link: "/news/",
    },
    {
      label: post.title,
      link: "",
    },
  ]

  return (
    <ContentStyled>
      <SEO
        title={search_title}
        description={search_description}
        headline={share_headline}
        share_comment={share_comment}
      />
      <Inner
        maxWidth="840px"
        margin="0 auto 150px auto"
        mobileMargin="0 0 50px 0"
      >
        <BreadCrumbs trail={trail} />
        <h1
          className="leftWidth"
          dangerouslySetInnerHTML={{
            __html: post.title,
          }}
        />
        <SVG name="SWave" color={theme.Blue} />
        <div
          className="body"
          dangerouslySetInnerHTML={{
            __html: post.content,
          }}
        />
        <Social
          sharing={{
            twitter_copy: share_comment,
          }}
        />
      </Inner>
    </ContentStyled>
  )
}

export default ({ data }) => {
  return (
    <Layout>
      <Content data={data} />
      <BottomColorNav />
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    allWordpressWpNews(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          title
          slug
          content
          acf {
            search_title
            search_description
            share_headline
            share_comment
          }
        }
      }
    }
  }
`
